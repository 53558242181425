<template>
  <div class="app-container">
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部停报客户" name="ninth">
        <contentTable ref="clientList" :tabName="tabName" v-if="tabName == 'ninth'" :listQuery="listQuery"></contentTable>
      </el-tab-pane>
    </el-tabs> -->
    <contentTableLS ref="clientList" :tabName="tabName" v-if="tabName == 'ninth'" :listQuery="listQuery"></contentTableLS>
    <!-- <div class="bottom_style">
      <div class="bottom_button">
        <el-button size="small" type="primary" plain >还原到未分配客户</el-button>
      </div>
    </div> -->
  </div>
</template>

<script>
import contentTableLS from "./components/contentTableLS.vue";
export default {
  name: "stopReporting",
  components:{
    contentTableLS,
  },
  data() {
    return {
      activeName: "ninth",
      tabName:'ninth',
      listQuery:{
        status:'3'
      }
    }
  },
  created(){
    this.initBus()
  },
  methods:{
    initBus(){
      this.$bus.off("stopReportingUpdate")

      this.$bus.on("stopReportingUpdate", (val) => {
        this.getList()
      });
    },
    getList(){
      this.$refs.clientList.getList()
    },
  }
}
</script>

<style>

</style>