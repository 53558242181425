<template>
  <el-select style="width:100%;border: 0;" filterable v-model="code" placeholder="请选择" size="small" clearable @change="changeValue">
    <el-option
    v-for="(itemcode,index) in this.list" 
    :key="index" 
    :label="itemcode.name3" 
    :value="itemcode.code"></el-option>
  </el-select>
</template>
<script>

export default {
  props:{
    newCode: {
      type: Number,
      default: 0
    },
    comId:{
      type:Number,
      default:0
    },
    list:{
      type:Array,
      default:[]
    },
    listAll:{
      type:Array,
      default:[]
    },
    subjectMatchName:{
      type:String,
      default:''
    },
    addName:{
      type:Boolean,
      default:true
    },
  },
  created() {
    console.log(this.list);
  },
  data(){
    return {
    }
  },
  computed: {
    code: {
      get() {
        if(!this.newCode){
          return ""
        }
        return this.newCode
      },
      set(val) {
        this.$emit("update:newCode", val)
        this.$emit("success", val)
      }
    }
  },
  methods: {
    addSubject(){
      this.$refs.addMatchSubject.dialogVisible = true
    },
    updateSubjectList(row){
      console.log(row);
      this.$emit('success2',row)
    },
  }
}
</script>