<template>
  <el-dialog :close-on-click-modal="false"
    v-model="dialogVisible"
    title="匹配科目"
    width="50%"
  >
    <div style="margin-bottom:10px">
    </div>
    <el-table :data="oldList" style="width: 100%" border  max-height="450">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="原科目" min-width="150">
        <template #default="scope">
          <span>{{+ scope.row.oldCode }}</span>
        </template>
      </el-table-column>
      <el-table-column label="示例" min-width="320">
        <template #default="scope">
          <span>{{ scope.row.oldName }}</span> 
        </template>
      </el-table-column>
      <el-table-column label="匹配科目" min-width="180">
        <template #default="scope">
          <matchSubjectNewCode :list="this.listAll" :listAll="this.listAll" :addName="true" :comId="this.comId" :subjectMatchName="scope.row.oldCode+scope.row.oldName" v-model:newCode="scope.row.newCode" ></matchSubjectNewCode>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="batchMatch" :loading="newLoading">确认</el-button>
      </span>
    </template>
    <!-- <span>当前已导入凭证账期{{affiliationPeriod}}</span> -->
  </el-dialog>

</template>

<script>
import { robotMatch } from "@/api/old"
import matchSubjectList from "./matchSubjectList.vue"
import matchSubjectNewCode from "./matchSubjectNewCode.vue"

export default {
  name:'batchMatchSubject',
  components:{ matchSubjectList ,matchSubjectNewCode },
  props:{
    affiliationPeriod:{
      type:String,
      default:''
    },
    oldList:{
      type:Array,
      default:[]
    }
  },
  data(){
    return {
      dialogVisible:false,
      list:[],
      listAll:[],
      innerList:[],
      comId:0,
      isLoading:false,
      newLoading:false
    }
  },
  created(){
  },
  methods:{
    init(comIds,listAll){
      this.comId = comIds
      this.dialogVisible = true
      this.listAll = listAll
    },
    batchMatch(){
      let param = {
        comIds:this.comId,
        list:this.oldList
      }
      let status = false
      param.list.map(v => {
       if(!v.newCode || !v.oldCode || !v.oldName){
          status = true
        }
      })
      if(status){
        this.$qzfMessage('请补充完整',2)
        return
      }
      this.newLoading = true
      
      robotMatch(param).then(res=>{
        this.newLoading = false
        if(res.data.msg == "success"){
          this.$qzfMessage('任务已发起')
          this.$emit('success')
          this.dialogVisible = false
        }
      })
    },
  }
}
</script>

<style>

</style>