<template>
  <div class="app-container" v-loading="loading">
   <!-- todo -->
    <div class="top_btns">
      <div class="left_box">
        <!-- <el-date-picker @change="getList" :clearable="false" v-model="listQuery.period" type="month" value-format="YYYYMM" size="small" placeholder="日期" style="width: 160px;margin-right:5px;"></el-date-picker> -->
        <el-input placeholder="请输入企业名称" v-model="listQuery.name" style="width: 160px;" class="filter-item" size="small" @keyup.enter="getList"/>
        <el-button class="filter-item1" type="primary" size="small" @click="getList"   >
          <el-icon><Search /></el-icon> <span  >搜索</span>
        </el-button>
        <search @success="getList">
          <el-form style="margin:11px 0px 0px 6px;width:700px" class="styleForm">
            <el-form-item label="选择人员 ：" :label-width="formLabelWidth">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId"></selectuser>
            </el-form-item>
            <el-form-item label="建账状态 ：" :label-width="formLabelWidth"> 
              <selectJzStatus v-model:jzStatus="listQuery.setUpStatusJz"></selectJzStatus>
            </el-form-item>
            <el-form-item label="采集状态：" :label-width="formLabelWidth">
              <selectCjJzStatus v-model:allStatus="listQuery.allStatus"></selectCjJzStatus>

              <!-- <el-checkbox-group size="small" v-model="listQuery.taskStatus">
                <el-checkbox-button v-for="item in checkOptions" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button>
              </el-checkbox-group> -->
            </el-form-item>
            <el-form-item label="导入状态：" :label-width="formLabelWidth">
              <el-checkbox-group size="small" v-model="listQuery.importTaskStatus">
                <el-checkbox-button v-for="item in importOptions" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="匹配状态：" :label-width="formLabelWidth">
              <el-checkbox-group size="small" v-model="listQuery.matchTaskStatus">
                <el-checkbox-button v-for="item in matchOptions" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <el-button size="small" type="primary" @click="declaration()"   >
          <el-icon><Aim /></el-icon><span  > 批量采集</span>
        </el-button>
        <el-button size="small" type="primary" @click="batchMatch()"   >
           批量匹配
        </el-button>
      </div>
    </div>
    <div class="none-border">
      <el-table stripe :data="list" :height="contentStyleObj" border @selection-change="handleSelectionChange" @filter-change="tableFilterChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" min-width="55" />
        <el-table-column align="center" type="index" label="编号" min-width="50" />
        <el-table-column align="left" prop="name" label="公司名称" min-width="250" class="gsmc">
          <template #default="scope">
            <span v-if="scope.row.type == '1'" class="xgmStyle">小</span>
            <span v-if="scope.row.type == '2'" class="ybStyle">般</span>
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="districtName" label="建账状态" min-width="180">
          <template #default="scope">
            <div class="item_icon">
              <p v-if="scope.row.setUpStatusJz == '6'"><i class="iconfont icon-gantanhao"></i>{{statusJz(scope.row.setUpStatusJz)}}</p>
              <p v-if="scope.row.setUpStatusJz == '2'"><i class="iconfont icon-info"></i>{{statusJz(scope.row.setUpStatusJz)}}</p>
              <p v-if="scope.row.setUpStatusJz == '1'"><i class="iconfont icon-duihao"></i>{{statusJz(scope.row.setUpStatusJz)}}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="districtName" label="采集" min-width="200">
          <template #default="scope">
            <div class="div_p">
              <div class="item_icon">
                <p>
                  <i :class="$setUpStatusJzCjIcon(scope.row.taskStatus,scope.row.bussinessStatus)"></i>
                  <el-tooltip v-if="scope.row.bussinessLog" class="scope.row" effect="dark" :content="scope.row.businessLog?scope.row.businessLog:'' + scope.row.errLog?scope.row.errLog:''" placement="top-start">
                    <el-button size="small" class="sbcg" style="border: 0;">{{$setUpStatusJzCj(scope.row.taskStatus, scope.row.bussinessStatus)}}</el-button>
                  </el-tooltip>
                  <span v-else>
                    <el-button size="small" class="sbcg" style="border: 0;">{{$setUpStatusJzCj(scope.row.taskStatus, scope.row.bussinessStatus)}}</el-button>
                  </span>
                </p>
              </div>
              <el-button @click="declareDetail(scope.row)" size="small" type="text">采集记录</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="districtName" label="导入" min-width="200">
          <template #default="scope">
            <div class="div_p">
              <div class="item_icon">
                <p v-if="scope.row.importTaskStatus == '0'"><i class="iconfont icon-gantanhao"></i>{{$setUpStatusImport(scope.row.importTaskStatus)}}
                </p>
                <p v-if="scope.row.importTaskStatus == '1'"><i class="iconfont icon-info"></i>{{$setUpStatusImport(scope.row.importTaskStatus)}}
                </p>
                <p v-if="scope.row.importTaskStatus == '2'"><i class="iconfont icon-info"></i>{{$setUpStatusImport(scope.row.importTaskStatus)}}</p>
                <p v-if="scope.row.importTaskStatus == '3'"><i class="iconfont icon-duihao"></i>
                <el-tooltip class="item" effect="dark" :content="scope.row.importErrLog" placement="top-start">
                  <el-button size="small" class="sbcg" style="border: 0;">{{$setUpStatusImport(scope.row.importTaskStatus)}}</el-button>
                </el-tooltip>
                </p>
                <p v-if="scope.row.importTaskStatus == '4'"><i class="iconfont icon-cuowu"></i>
                <el-tooltip class="item" effect="dark" :content="scope.row.importErrLog" placement="top-start">
                  <el-button size="small" class="sbyc" style="border: 0;">{{$setUpStatusImport(scope.row.importTaskStatus)}}</el-button>
                </el-tooltip>
                </p>
                <p v-if="scope.row.importTaskStatus == '5'"><i class="iconfont icon-cuowu"></i>{{$setUpStatusImport(scope.row.importTaskStatus)}}</p>
                <!-- <p v-if="scope.row.importTaskStatus == '6'"><i class="iconfont icon-info"></i>{{$setUpStatusImport(scope.row.importTaskStatus)}}</p> -->
              </div>
              <el-button @click="declareDetailsImport(scope.row)" size="small" type="text">导入记录</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="districtName" label="匹配" min-width="200">
          <template #default="scope">
            <div class="div_p">
              <div class="item_icon">
                <p v-if="scope.row.matchTaskStatus == '0'"><i class="iconfont icon-gantanhao"></i>{{$setUpStatusMatch(scope.row.matchTaskStatus)}}
                </p>
                <p v-if="scope.row.matchTaskStatus == '1'"><i class="iconfont icon-info"></i>{{$setUpStatusMatch(scope.row.matchTaskStatus)}}
                </p>
                <p v-if="scope.row.matchTaskStatus == '2'"><i class="iconfont icon-info"></i>{{$setUpStatusMatch(scope.row.matchTaskStatus)}}</p>
                <p v-if="scope.row.matchTaskStatus == '3'"><i class="iconfont icon-duihao"></i>
                <el-tooltip class="item" effect="dark" :content="scope.row.matchErrLog" placement="top-start">
                  <el-button size="small" class="sbcg" style="border: 0;">{{$setUpStatusMatch(scope.row.matchTaskStatus)}}</el-button>
                </el-tooltip>
                </p>
                <p v-if="scope.row.matchTaskStatus == '4'"><i class="iconfont icon-cuowu"></i>
                <el-tooltip class="item" effect="dark" :content="scope.row.matchErrLog" placement="top-start">
                  <el-button size="small" class="sbyc" style="border: 0;">{{$setUpStatusMatch(scope.row.matchTaskStatus)}}</el-button>
                </el-tooltip>
                </p>
                <p v-if="scope.row.matchTaskStatus == '5'"><i class="iconfont icon-cuowu"></i>{{$setUpStatusMatch(scope.row.matchTaskStatus)}}</p>
                <!-- <p v-if="scope.row.matchTaskStatus == '6'"><i class="iconfont icon-info"></i>{{$setUpStatusMatch(scope.row.matchTaskStatus)}}</p> -->
              </div>
              <el-button @click="declareDetailsMatch(scope.row)" size="small" type="text">匹配记录</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="manager" label="操作" min-width="180">
          <template #default="scope">
            <div class="div_p">
              <el-button @click="importHisVoucher(scope.row)" size="small" type="text">导入凭证</el-button>
              <el-button size="small" type="text" @click="matchSubjects(scope.row)">匹配</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
    <!-- <div>
      <el-button size="small" type="danger" @click="batchRevocation">批量撤销</el-button>
    </div> -->
  </div>
  <!-- 批量采集弹窗 -->
  <el-dialog :close-on-click-modal="false"
    v-model="dialogVisible"
    title="批量采集"
    width="30%"
    destroy-on-close>
    <el-form :rules="rules" ref="ruleForm" :model="form">
      <el-form-item label="软件名称" :label-width="formLabelWidth">
        <el-select v-model="form.name" placeholder="请选择软件" size="small">
          <!-- <el-option label="金财互联" value="金财互联"></el-option> -->
          <el-option label="云帐房" value="云帐房"></el-option>
          <el-option label="亿企代账" value="亿企代账"></el-option>
          <el-option label="柠檬云" value="柠檬云"></el-option>
          <el-option label="柠檬云免费版" value="柠檬云免费版"></el-option>

          <el-option label="浪潮云" value="浪潮云"></el-option>
          <el-option label="木牛盒子" value="木牛盒子"></el-option>
          <el-option label="精斗云" value="精斗云"></el-option>
          <el-option label="唯易" value="唯易"></el-option>
          <el-option label="芸豆会计" value="芸豆会计"></el-option>
          <el-option label="易代账" value="易代账"></el-option>
          <el-option label="诺诺云" value="诺诺云"></el-option>
          <el-option label="账无忧" value="账无忧"></el-option>
          <el-option label="云代账" value="云代账"></el-option>
          <el-option label="帐务云" value="帐务云"></el-option>
          <el-option label="好会计" value="好会计"></el-option>
          <el-option label="天蓬云账" value="天蓬云账"></el-option>
          <el-option label="金账师" value="金账师"></el-option>
          <el-option label="快账" value="快账"></el-option>






          <!-- <el-option label="快合财税" value="快合财税"></el-option>
          <el-option label="代账魔方" value="代账魔方"></el-option> -->



        </el-select>
      </el-form-item>

      <el-form-item prop="userName" label="用户名" :label-width="formLabelWidth">
        <el-input style="width:200px" v-model="form.userName" size="small"></el-input>
      </el-form-item>

      <el-form-item prop="password" label="密码" :label-width="formLabelWidth">
        <el-input style="width:200px" v-model="form.password" size="small"></el-input>
      </el-form-item>

      <el-form-item v-if="form.name == '云代账'|| form.name == '代账魔方' || form.name == '帐务云'" prop="orgCode" label="公司代码" :label-width="formLabelWidth">
        <el-input style="width:200px" v-model="form.orgCode" size="small"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" size="small">确定</el-button>
      </span>
    </template>
  </el-dialog>

  <qzf-import-back :hideButton="true" @success="getList()" @success2="importHisVoucher2" ref="historyVoucher" from="历史凭证" :comId="importComId"></qzf-import-back>
  <qzf-import-back :hideButton="true" @success="getList()" @success2="importHisSubject2" ref="historySubject" from="历史科目" :comId="importComId"></qzf-import-back>

  <otherHisSubject ref="otherHisSubject" @success="importHisSubject3"></otherHisSubject>
  <otherHisVoucher ref="otherHisVoucher" @success="importHisVoucher3"></otherHisVoucher>
  
  <declareDetails ref="declareDetails"></declareDetails>
  <declareDetails ref="declareDetailsImport"></declareDetails>
  <declareDetails ref="declareDetailsMatch"></declareDetails>
  <matchSubject ref="matchSubject" @success="getList" @success2="matchSubjectss" :oldList ="this.oldList" :affiliationPeriod="this.affiliationPeriod"></matchSubject>
  <batchMatchSubject :oldList ="this.oldList" ref="batchMatchSubject"></batchMatchSubject>

</template>

<script>
import { companyHisImportList } from "@/api/company"
import { importVoucherHis , importHisSubjectFall ,oldMatchSubject , robotMatchSubject } from "@/api/old"
import {  findSubjects } from "@/api/subject"

import declareDetails from "../../batch/components/declareDetails.vue";
import otherHisSubject from "@/components/import/son/otherHisSubject"
import otherHisVoucher from "@/components/import/son/otherHisVoucher"
import selectJzStatus from "@/components/Screening/selectJzStatus";
import selectCjJzStatus from "./selectCjJzStatus.vue"

import matchSubject from "./matchSubject.vue"
import batchMatchSubject from "./batchMatchSubject"
import { currentAccountPeriod } from '@/utils'
import { sendTask , quitTask} from "@/api/collection"
export default {
  name: "batchSendSb",
  components:{
    declareDetails,
    otherHisSubject,
    otherHisVoucher,
    matchSubject,
    selectJzStatus,
    selectCjJzStatus,
    batchMatchSubject
  },
  data(){
    return {
      activeName:'税款申报',
      formLabelWidth: '120px',
      listQuery: {
        taskName:"his-cj-historyCollect",
        period: currentAccountPeriod(),
        name: '',
        taskStatus: [],
        importTaskStatus:[],
        matchTaskStatus:[],
        taxNames:[],
        allStatus:[],
        page: 1,
        limit: 20,
      },
      total:0,
      list:[],
      form: {
        name: '云帐房',
        userName:'',
        password:'',
        orgCode:''
      },
      rules: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        orgCode: [
          { required: true, message: '公司代码', trigger: 'blur' },
        ],
      },
      gsMainIds: undefined,
      pageStatus: true,

      dialogVisible: false,
      checkOptions: [
        {
          value: 0,
          label: '未采集',
        },
        {
          value: 1,
          label: '排队中',
        },
        {
          value: 2,
          label: '采集中',
        },
        {
          value: 3,
          label: '采集完成',
        },
        {
          value: 4,
          label: '采集失败',
        },
      ],
      importOptions: [
        {
          value: 0,
          label: '未导入',
        },
        {
          value: 1,
          label: '排队中',
        },
        {
          value: 2,
          label: '导入中',
        },
        {
          value: 3,
          label: '导入完成',
        },
        {
          value: 4,
          label: '导入失败',
        },
      ],
      matchOptions: [
        {
          value: 0,
          label: '未匹配',
        },
        {
          value: 1,
          label: '排队中',
        },
        {
          value: 2,
          label: '匹配中',
        },
        {
          value: 3,
          label: '匹配完成',
        },
        {
          value: 4,
          label: '匹配失败',
        },
      ],
      categories: [
        {text: '利润表', value: 'kj_lrb'}, 
        {text: '资产负债表', value: 'kj_zcfz'}, 
        {text: '增值税及附加税一般纳税人申报表', value: 'gs_vat'}, 
        {text: '小规模增值税纳税及附加税申报表', value: 'gs_small_vat'},
        {text: '财产和行为税纳税申报表', value: 'gs_deed'},
        {text: '企业所得税申报表', value: 'gs_tax_quarter'},
        {text: '综合所得个人所得税预扣预缴申报表', value: 'gs_gs'},
        {text: '附加税', value: 'gs_fjs'},
        {text: '文化事业建设费申报表', value: 'gs_whsyjsfsbbygz'},
        {text: '印花税纳税申报表', value: 'gs_stamp_duty_item'},
        {text: '残疾人就业保障金申报表', value: 'gs_cbj'},
        {text: '工会经费', value: 'gs_ghjf'},
        {text: '房产税土地税', value: 'gs_fcs'},
        {text: '非税收入', value: 'gs_fssr'},
        {text: '印花税纳税申报表', value: 'gs_stamp_duty_item_cb'},
        {text: '印花税纳税申报表', value: 'gs_stamp_duty_item_jb'},
        {text: '水利建设专项收入', value: 'gs_sl'},
        {text: '城市生活垃圾处置费', value: 'gs_ljcl'},
        {text: '工会经费', value: 'gs_qtsr'},
        {text: '个人生产经营所得(查账征收)', value: 'gs_gs_one'},
        {text: '现金流量表', value: 'kj_xjllb'},
        {text: '单位社会保险费日常申报(全责)', value: 'gs_sb'},
      ],
      importComId:0,
      beginTime:'',
      endTime:'',
      importPeriod:'',
      loading:false,
      listLow:[],
      multipleSelection:[],
      oldList:[],
      affiliationPeriod:''
    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  created(){
    this.getList()
    this.initBus()
    this.contentStyleObj= this.$getHeight(260)
  },
  methods:{
    initBus(){
      this.$bus.on("refreshTask", () => {
        if(!this.pageStatus){
          return
        }
        if(this.gsMainIds && this.gsMainIds.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          if(res.importTaskStatus == 2 || res.importTaskStatus == 1 || res.taskStatus == 2) {
            status = true
          }
        })
        if(status == true) {
          this.getList()
        }
      });
    },
    getList(){
      companyHisImportList(this.listQuery).then(res=>{
        this.list = res.data.data.list
        this.total = res.data.data.total
      })
    },
    handleClick() {
      if(this.activeName == "税款申报") {
        this.listQuery.accountingType = 2
        this.getList()
      }if(this.activeName == "零申报") {
        this.listQuery.accountingType = 1
        this.getList()
      }
    },
    tableFilterChange(filters) {
      const values=Object.values(filters)
      const value=values[0]
      this.listQuery.taxNames=value
      this.getList()
    },
    handleSelectionChange(row) {
      this.multipleSelection = row;
      this.gsMainIds = []
      row.map(info=>{
        this.gsMainIds.push(info.comId)
      })
    },
    // 采集详情
    declareDetail(e) {
      this.$refs.declareDetails.init(e.comId,e.startAccountPeriod,e.taskName)
    },
    // 导入详情
    declareDetailsImport(e){
      this.$refs.declareDetailsImport.init(e.comId,e.startAccountPeriod,e.importTaskName)
    },
    // 匹配详情
    declareDetailsMatch(e){
      this.$refs.declareDetailsMatch.init(e.comId,e.startAccountPeriod,e.matchTaskName)
    },
    //批量采集
    declaration() {
      if(!this.gsMainIds){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let data = localStorage.getItem("lssj")
      if(data){
        this.form = JSON.parse(data)
      }
      this.dialogVisible = true
    },
    //批量采集确定
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let cond = {
              // comId: v * 1,
              orgCode: this.form.orgCode,
              userName: this.form.userName,
              password: this.form.password,
              softwareName: this.form.name
            }
          //临时保存账号密码
          localStorage.setItem("lssj",JSON.stringify(this.form))
          this.dialogVisible = false
          sendTask({comIds:this.gsMainIds,taskName: "his-cj-historyCollect",cond}).then(res => {
            if(res.data.msg == 'success') {
              this.$qzfMessage('任务已发起',3)
              this.getList()
              this.$queueMessage(res.data.data.queue)

            }
          })
        }
      });
    },
    //申报
    handleUpdate(e) {
      this.$confirm('确定要检查吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          taskName: 'tax-jc-shenbao',
          comIds: [e.comId]
        }
        sendTask(param).then(res => {
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)

          }
        })
      })
    },
    importHisVoucher(row){
      this.importComId = row.comId
      this.$refs.historyVoucher.handImport()
    },
    importHisSubject(row){
      this.importComId = row.comId
      this.$refs.historySubject.handImport()
    },
    importHisSubject2(e){
      this.importPeriod = e.period
      this.$refs.otherHisSubject.dialogFormVisible = true
      this.$refs.otherHisSubject.init(e.item)
    },
    importHisVoucher2(e){
      console.log(e);
      this.beginTime = e.beginTime
      this.endTime = e.endTime
      this.$refs.otherHisVoucher.dialogFormVisible = true
      this.$refs.otherHisVoucher.init(e.item)
    },
    importHisSubject3(e){
      console.log(e);
      this.loading = true
      let param = {
        comId:this.importComId,
        period:this.importPeriod,
        item:e.arr
      }
      importHisSubjectFall(param).then(res=>{
        this.loading = false
        if(res.data.msg == "success"){
          this.$qzfMessage('导入成功')
          this.getList()
        }
      })
    },
    importHisVoucher3(e){
      console.log(e);
      this.loading = true
      let param = {
        beginTime:this.beginTime,
        endTime:this.endTime,
        comId:this.importComId,
        item:e.arr
      }
      importVoucherHis(param).then(res=>{
        this.loading = false
        if(res.data.msg == "success"){
          this.$qzfMessage('导入成功')
          this.getList()
        }
      })
    },
    // 匹配
    async matchSubjects(row){
      if(row.importTaskStatus != 3){
        this.$qzfMessage('请先导入凭证',2)
        return
      }
      await oldMatchSubject({comId:row.comId}).then(res=>{
        this.oldList = res.data.data.list
        this.affiliationPeriod = res.data.data.periods.join("-")
      })
      await findSubjects({comId:row.comId}).then(res=>{
        this.listLow = res.data.data.listLow
        this.listAll = res.data.data.list
        if(!this.listLow){
          this.$qzfMessage('请先进行建账',2)
          return
        }
        this.$refs.matchSubject.init(row.comId,this.listLow,this.listAll)
      })
    },
    matchSubjectss(row){
      findSubjects({comId:row}).then(res=>{
        this.listLow = res.data.data.listLow
        this.listAll = res.data.data.list
        this.$refs.matchSubject.init(row,this.listLow,this.listAll)
      })
    },
    batchRevocation(){
      if(this.multipleSelection.length == 0){
        this.$qzfMessage('请至少选择一家公司',2)
        return
      }
      let status = false
      this.multipleSelection.map(v=> {
        if(v.taskStatus !=1){
          status = true
        }
      })
      if(status){
        this.$qzfMessage("只有排队中的才可以进行撤销", 2)
        return
      }
      let reqNos = []
      this.multipleSelection.map(v=>{
        reqNos.push(v.reqNo)
      })
      console.log(reqNos);
      quitTask({reqNos}).then(res =>{
        console.log(res);
        if(res.data.msg == "success"){
          this.getList()
          this.$qzfMessage("撤销成功")
        }
      })
    },
    async batchMatch(){
      if(!this.gsMainIds){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      await robotMatchSubject(this.gsMainIds).then(res=>{
        if(res.data.msg == "success"){
          this.oldList = res.data.data.list
          this.listAll = res.data.data.listAll
          this.comIds = res.data.data.comIds
          this.$refs.batchMatchSubject.init(this.comIds,this.listAll)
        }
      })
    },
    statusJz(e){
      if(e == 1){
        return '已建账'
      }else if(e ==2){
        return '无需建账'
      }else if(e == 6){
        return '未建账'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
.filter-item1 {
  margin-right: 10px;
}
 
.el-table__column-filter-trigger .el-icon-arrow-down {
  color: white !important;
  width: 10px;
}
.none-border .div_p {
  line-height: 30px;
  border-bottom: 1px solid #eee;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}
.xgmStyle,.ybStyle {
  margin-left: 10px;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 28px;
    font-size: 16px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  p{
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    color: #333;
  }
}
.sbcg {
  padding: 0 !important;
}
.sbyc {
  color: red !important;
  padding: 0 !important;
}
.el-select {
  width: 200px;
  margin-left:0px
}
</style>
<style lang="scss">
.el-table__column-filter-trigger i {
  color: black !important;
  width: 10px;
}
.none-border .cell {
  padding: 0;
}
.el-table th.el-table__cell>.cell.highlight {
    color: black !important;
}
</style>