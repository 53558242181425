<template>
  <div>
    <!-- 导入其他凭证 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="凭证配置" v-model="dialogFormVisible" >
			<el-form ref="dataForm" :model="temp" label-position="right" label-width="120px" :inline="true">
					<div class="common_dialog_box clearfix">
            <!-- <div class="period_box">
              <span>请选择起止账期：</span>
              <el-date-picker style="width:160px;" v-model="startTime" type="month" placeholder="请选择开始账期" size="small" value-format="yyyyMM"></el-date-picker>
              -
              <el-date-picker style="width:160px;" v-model="endTime" type="month" placeholder="请选择结束账期" size="small" value-format="yyyyMM"></el-date-picker>
            </div> -->

						<div class="common_title">类型</div>
							<div class="item_one clearfix">
                <el-form-item label="" class="item">
									<el-radio-group v-model="dataCodeType">
											<el-radio :label="1">科目编码+科目名称两列</el-radio>
											<el-radio :label="2">科目编码+科目名称一列</el-radio>
									</el-radio-group>
                </el-form-item>
							</div>
						</div>
					<div class="common_dialog_box clearfix">
					<div class="common_title">数据调整</div>
					<div class="item_one clearfix">
						<h6 style="color:red"><span>提示：</span>*请按照表头列次，调整导入数据的顺序</h6>
						<div class="table-big">
							<div class="table">
								<div v-if="dataCodeType == 1" style="border-bottom:1px solid #eee;background-color:#17a2b8;color:#fff;">
									<div class="tableHead" style="width:26px"></div>
									<div class="tableHead">日期</div>
									<div class="tableHead">凭证字号</div>
									<div class="tableHead">摘要</div>
									<div class="tableHead">科目代码</div>
									<div class="tableHead">科目名称</div>
									<div class="tableHead">借方</div>
									<div class="tableHead">贷方</div>
									<div class="tableHead">附件数</div>
								</div>
								<div v-if="dataCodeType == 2" style="border-bottom:1px solid #eee;background-color:#17a2b8;color:#fff">
									<div class="tableHead" style="width:26px"></div>
									<div class="tableHead">日期</div>
									<div class="tableHead">凭证字号</div>
									<div class="tableHead">摘要</div>
									<div class="tableHead">科目代码名称</div>
									<div class="tableHead">借方</div>
									<div class="tableHead">贷方</div>
									<div class="tableHead">附件数</div>
								</div>
                <draggable 
									v-model="list" 
									group="people" 
									@start="drag=true" 
									@end="drag=false" 
									item-key="id">
									<template #item="{element,index}">
										<div class="table-div">
											<div class="top_icon">
												<i @click="delItem(index)" class="iconfont icon-shanchu"></i>
											</div>
											<div v-for="(item2,index2) in element" :key="index2" class="table_icon">
												<span v-if="item2 != 'del'">
													{{item2}}
												</span>
												<span v-else>
													<i @click="delItemRaw(index2)" class="iconfont icon-shanchu"></i>
												</span>
											</div>
										</div>
									</template>
								</draggable>
							</div>
						</div>  
					</div>
				</div>
			</el-form>
			<template #footer>
				<div  class="dialog-footer">
					<el-button @click="dialogFormVisible = false" size="small">取消</el-button>
					<el-button type="primary" @click="updateData()" size="small">确定</el-button>
				</div>
			</template>
    </el-dialog>
  </div>

</template>
    

<script>

const delcommafy = function (num){
		if(!num) return num;
		num = num.toString();
		num = num.replace(/,/gi, '');
		return num;
};

import draggable from 'vuedraggable'
export default {
    props: {
    
    },
		components: {
      draggable,
    },
    data () {
			return {
				list: [
					[1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008],
          [2, 2, 3, 4, 5, 67, 33, 8],
          [3, 2, 3, 4, 5, 67, 33, 8],
          [4, 2, 3, 4, 5, 67, 33, 8],
          [5, 2, 3, 4, 5, 67, 33, 8],
          [6, 2, 3, 4, 5, 67, 33, 8],
          [7, 2, 3, 4, 5, 67, 33, 8],
          [8, 2, 3, 4, 5, 67, 33, 8],
          [9, 2, 3, 4, 5, 67, 33, 8],
				],
				dataCodeType: 1,
				dialogFormVisible: false,
			};
    },
    created(){
    },
    methods: {
        init(data){
					let long = 0
					data.map(v=>{
						if(long < v.length) long = v.length
					})
					console.log(data)
					let list = []
					data.map(v=>{
						for(let i=0;i<long;i++){
							if(!list[i]){
								list.push([])
							}
							if(v.length > i){
								list[i].push(v[i])
							} else {
								list[i].push("")
							}
						
						}
					}) 
					// console.log(list)
					let dd = this.$qzfCopy(list[0])
					for(let i=0;i<dd.length;i++){
						dd[i] = 'del'
					}
					list.unshift(dd)
					// list.push(dd)

					// console.log(list)
					this.list = list
        },
        delItem(index){
          // if(index == this.list.length-1){
          //   this.$message.error('本列不可删除');
          //   return
          // }
          this.list.splice(index,1);
        },
        delItemRaw(index){
					this.list.map(v=>{
						v.splice(index,1);
					})
        },
        updateData() {
          console.log(this.list);
          if (this.list.length < 10 && this.dataCodeType == 1) {
            this.$message.error("列数异常请退出当前页面重新导入");
            return;
          }
          if (this.list.length < 9 && this.dataCodeType == 2) {
            this.$message.error("列数异常请退出当前页面重新导入");
            return;
          }
          let arr = [];
          let oldPeriod = "" //账期
          let oldVoucherNo = "" //凭证号
          let oldTime = "" //日期
          for (let i = 0; i < this.list[1].length; i++) {
            let time = '' //日期
            let period = "" //账期
            let voucherNo = "" //凭证号
            //时间兼容
            if(this.list[1][i]){
              if(!isNaN(this.list[1][i])){
                // console.log(this.list[0][i]);
                let newTime = this.$newformatDate(this.list[1][i])
                time = newTime.split("").splice(0, 7);
                time.splice(4, 1);
                period = time.join("");
                time = newTime
              }else if(this.list[1][i].indexOf('-') > 0){
                time = this.list[1][i].split("").splice(0, 7);
                time.splice(4, 1);
                period = time.join("");
                time = this.list[1][i]
              }else if(this.list[1][i].indexOf('/') > 0){
                time = this.list[1][i].split("").splice(0, 7);
                time.splice(4, 1);
                period = time.join("");
                time = this.list[1][i].split('/').join('-')
              }else{
                this.$message.error("不支持的时间格式");
                return;
              }
            }else{
              period = oldPeriod
              time = oldTime
            }
            //凭证号
            if(this.list[2][i].toString() && this.list[2][i].toString().indexOf('记') > -1){
              voucherNo = this.list[2][i].toString()
            }else if(this.list[2][i].toString()){
              voucherNo = "记" + this.list[2][i].toString()
            }else{
              voucherNo = oldVoucherNo
            }
            // console.log(period,time,voucherNo)
            if(oldPeriod != period || oldVoucherNo != voucherNo){//新的插入一条
              oldPeriod = period
              oldVoucherNo = voucherNo
              oldTime = time
              let itemOne = {}
              if(this.dataCodeType == 1){
                itemOne = {
                  summary: this.list[3][i],
                  subjectCode: this.list[4][i].toString().trim(),
                  subjectName: this.list[5][i].toString().trim(),
                  inAmount: this.list[6][i]?this.list[6][i].toString().moneyFilter():0,
                  outAmount: this.list[7][i]?this.list[7][i].toString().moneyFilter():0,
                  billCount: this.list[8][i],
                }
              }else{
                let code = this.list[4][i].toString().trim()
                var patt1=new RegExp(`^[0-9,.]+`);
                code = patt1.exec(code).join('')
                itemOne = {
                  summary: this.list[3][i],
                  subjectCode: code,
                  subjectName: this.list[4][i].replace(code, "").toString().trim(),
                  inAmount: this.list[5][i]?this.list[5][i].toString().moneyFilter():0,
                  outAmount: this.list[6][i]?this.list[6][i].toString().moneyFilter():0,
                  billCount: this.list[7][i],
                }
              }
              arr.push({
                voucherDate: time,
                period: period,
                voucherNo: voucherNo,
                item: [
                  itemOne,
                ],
              });
            }else{
              let itemOne = {}
              if(this.dataCodeType == 1){
                itemOne = {
                  summary: this.list[3][i],
                  subjectCode: this.list[4][i].toString().trim(),
                  subjectName: this.list[5][i].toString().trim(),
                  inAmount: this.list[6][i]?this.list[6][i].toString().moneyFilter():0,
                  outAmount: this.list[7][i]?this.list[7][i].toString().moneyFilter():0,
                  billCount: this.list[8][i],
                }
              }else{
                let code = this.list[3][i].toString().trim()
                var patt1=new RegExp(`^[0-9,.]+`);
                code = patt1.exec(code).join('')
                itemOne = {
                  summary: this.list[3][i],
                  subjectCode: code,
                  subjectName: this.list[4][i].replace(code, "").toString().trim(),
                  inAmount: this.list[5][i]?this.list[5][i].toString().moneyFilter():0,
                  outAmount: this.list[6][i]?this.list[6][i].toString().moneyFilter():0,
                  billCount: this.list[7][i],
                }
              }
              arr[arr.length - 1].item.push(itemOne);
            }
          }
          // console.log(arr);
          this.dialogFormVisible = false;
          this.$emit("success", { arr: arr });
        },
    }
};
</script>
<style lang="scss" scoped>
.item_s {
    margin-bottom: 10px;
  }
  .table-big{
      width: 100%;
      height: 320px;
      overflow: auto;
      border: 1px solid #eee;
      margin-top: 10px;
    .table{
        min-width: 3000px;
        height: 400px;
        
        // white-space:nowrap;
        .table-div{
            vertical-align: top;
            display: inline-block;
            width: 100px;
            border-right: 1px solid #eee;
            div{
                height: 30px;
                line-height: 30px;
                width: 100%;
                // display: inline-block;
                display: flex;
                white-space: nowrap;
                overflow: hidden;
                font-size: 14px;
                color: #333;
                padding:0 5px;
            }
            .iconfont icon-shanchu{
                cursor: pointer;
            }
            &:first-child .top_icon i{
                display: none;
            }
            &:first-child{
                width: 26px !important;
            }
        }
        .top_icon{
            width: 100%;
            border-bottom: 1px solid #eee;
            i{
                font-size: 16px;
                color: #F56C6C;
                cursor: pointer;
                margin-top: 1px;
            }

        }
        .table_icon{
            border-bottom: 1px solid #eee;
            i{
                font-size: 16px;
                color: #F56C6C;
                cursor: pointer;
                margin-top: 7px;
            }
            
        }
    }
    
  }
.common_dialog_box .item{
	margin-left:0px !important;
	// width:48% !important
}
.common_dialog_box .common_title{
	font-weight: 600;
}
.tableHead{
	display:inline-block;
	width:100px;
	text-align:center;
	border-right:1px solid #eee;
	height:16px
}
</style>


