<template>
  <el-dialog :close-on-click-modal="false"
    v-model="dialogVisible"
    title="匹配科目"
    width="50%"
  >
    <div style="margin-bottom:10px">
      <el-date-picker size="small" style="width:150px" v-model="beginMonth" type="month" placeholder="选择日期" value-format="YYYYMM" :disabled-date="disabledDate"></el-date-picker>
      <span style="margin-left:10px;color: red;">提示：最多一次性匹配20条</span>
      <el-button size="small" style="float:right" type="primary" @click="createNextDialog">创建下级科目</el-button>
    </div>
    <el-table :data="oldList" style="width: 100%" border  max-height="450" @selection-change="handleSelectionChange">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" width="55">
      </el-table-column>
      <el-table-column label="原科目" min-width="320">
        <template #default="scope">
          <span>{{scope.row.oldCode+scope.row.oldName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="匹配科目" min-width="180">
        <template #default="scope">
          <matchSubjectList :list="this.list" :listAll="this.listAll" :addName="true" :comId="this.comId" :subjectMatchName="scope.row.oldCode+scope.row.oldName" v-model:subjectId="scope.row.subjectId" @success2="updateSubjectList"></matchSubjectList>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="batchSave" :loading="newLoading">暂存</el-button>
        <el-button size="small" type="primary" @click="batchMatch" :disabled="!!this.oldList">确认</el-button>
      </span>
    </template>
    <span>当前已导入凭证账期{{affiliationPeriod}}</span>
  </el-dialog>

  <el-dialog :close-on-click-modal="false"
    v-model="innerDialogVisible"
    title="创建科目"
    width="50%"
  >
    <div style="margin-bottom:20px">
      请选择科目名称分隔符:
      <el-select v-model="sep" class="m-2" placeholder="请选择科目名称分隔符" size="small" @change="changeSep">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
     <span style="font-weight:600;margin-left:20px">示例: <span v-show="!!this.sep">银行存款</span>{{this.sep}}农业银行</span>
    </div>
    <el-table :data="innerList" style="width: 100%" border size="small" max-height="450">
      <el-table-column label="原科目" min-width="320">
        <template #default="scope">
          <span>{{scope.row.oldCode+scope.row.oldName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="上级科目" min-width="180">
        <template #default="scope">
          <matchSubjectList :list="this.listAll" :listAll="this.listAll" v-model:subjectId="scope.row.upSubjectId" :addName="true" :comId="this.comId" :subjectMatchName="scope.row.oldCode+scope.row.oldName" @success2="updateSubjectList"></matchSubjectList>
        </template>
      </el-table-column>
      <el-table-column label="科目名称" min-width="280">
        <template #default="scope">
          <el-input size="small" v-model="scope.row.name"></el-input>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="innerDialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="batchInnerSave" :loading="isLoading">创建</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>
import { oldMatchSubject , saveOldSubjectBind ,oldMatch ,oldSubjectSaves } from "@/api/old"
import {  findSubjects } from "@/api/subject"
import matchSubjectList from "./matchSubjectList.vue"

export default {
  name:'matchSubject',
  components:{ matchSubjectList },
  props:{
    affiliationPeriod:{
      type:String,
      default:''
    },
    oldList:{
      type:Array,
      default:[]
    }
  },
  data(){
    return {
      beginMonth: "",//开始账期
      dialogVisible:false,
      innerDialogVisible:false,
      list:[],
      listAll:[],
      innerList:[],
      comId:0,
      allMatchSubejct:[],
      sep:'',
      options:[{
        value:'-',
        label:'-'
      },
      {
        value:'_',
        label:'_'
      },
      {
        value:'.',
        label:'.'
      },
      { 
        value:'',
        label:'无'
      },
      {
        value:'→',
        label:'→'
      }],
      isLoading:false,
      newLoading:false
    }
  },
  created(){
    console.log(this.oldList);
  },
  methods:{
    init(comId,list,listAll){
      this.comId = comId
      this.dialogVisible = true
      this.list = list
      this.listAll = listAll
      this.beginMonth = this.affiliationPeriod.split("-")[this.affiliationPeriod.split("-").length - 1]
    },
    saveMatch(row){
      let param = {
        comId:this.comId,
        subjectId:row.subjectId,
        oldCode:row.oldCode
      }
      saveOldSubjectBind([param]).then(res=>{
        if(res.data.msg == 'success'){
          this.$qzfMessage('匹配成功')
        }
      })
    },
    handleSelectionChange(val) {
      this.allMatchSubejct = val;
      this.innerList = val
    },
    batchSave(){
      if(this.allMatchSubejct.length == 0){
        this.$qzfMessage("请至少选择一条",2)
        return
      }
      this.allMatchSubejct.map(v=>{
        v['comId'] = this.comId
        v['sep'] = this.sep
      })
      this.newLoading = true
      saveOldSubjectBind(this.allMatchSubejct).then(res=>{
        this.newLoading = false
        if(res.data.msg == 'success'){
          this.$qzfMessage('匹配成功')
          this.$emit('success')
          this.dialogVisible = false
        }
      })
      
    },
    batchMatch(){
      // 
      if(!this.beginMonth){
        this.$qzfMessage('请选择开始账期',1)
        return
      }
      this.$confirm('请确定开始账期' + this.beginMonth + "历史数据生成后不可更改！", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          comId:this.comId,
          beginTime:this.beginMonth,
        }
        oldMatch(params).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage('任务已发起')
            this.$emit('success')
            this.dialogVisible = false
          }
        })
      }).catch(() => {    
      });
    },
    updateSubjectList(row){
      this.$emit('success2',row)
      console.log(this.innerList);
    },
    //更改sep
    changeSep(){
      this.innerList.map(v=>{
        if(this.sep){
          v.name = v.oldName.split(this.sep)[v.oldName.split(this.sep).length - 1]
        }else{
          v.name = v.oldName
        }
      })
    },
    createNextDialog(){
      console.log(this.innerList);
      if(this.innerList.length == 0){
        this.$qzfMessage('请至少选择一条科目',2)
        return
      }
      this.innerList.map(v=>{
        v.name = v.oldName
      })
      this.sep = ""
      this.innerDialogVisible = true
    },
    batchInnerSave(){
      this.isLoading = true
      oldSubjectSaves(this.innerList).then(res => {
        this.isLoading = false
        if(res.data.msg == "success"){
          this.getNewList()
          this.innerDialogVisible = false
          let newList = res.data.data.list
          for(var i = 0; i< newList.length;i++){
            for(var j = 0;j< this.oldList.length; j++){
              if(newList[i].oldCode == this.oldList[j].oldCode){
                this.oldList[j].subjectId = newList[i].subjectId
              }
            }
          }
        }
      })
    },
    getNewList(){
      findSubjects({comId:this.comId}).then(res => {
        this.list = res.data.data.listLow
        this.listAll = res.data.data.list
      })
    },
  }
}
</script>

<style>

</style>